import type { ISiteApis, IWixAPI } from '@wix/yoshi-flow-editor';

import {
  GROUPS_APP_DEFINITION_ID,
  MEMBERS_APP_DEFINITION_ID,
} from '../constants';

type MembersAreaRoute = {
  home: boolean;
  path: string;
  private: boolean;
  state: string;
  widgetId: string;
};

const MA_PROFILE_WIDGET_ID = '14dbefb9-3b7b-c4e9-53e8-766defd30587';

/**
 * Resolve verticals base urls
 * please bear in mind that object keys should be named the same as routes names.
 * router will inject baseUrls to them during registration stage
 */
export async function resolveVerticalsBaseUrls(api: IWixAPI) {
  const [group, groups, members, membersProfile, myGroups] = await Promise.all([
    getGroupPageUrl(api.site),
    getGroupListPageUrl(api.site),
    getMembersPageUrl(api.site),
    getMembersProfilePageUrl(api.site),
    getMyGroupsPageUrl(api),
  ]);

  return {
    group: removeTrailingSlash(group),
    groups: removeTrailingSlash(groups),
    members: removeTrailingSlash(members),
    'members.profile': removeTrailingSlash(membersProfile),
    'my-groups': removeTrailingSlash(myGroups),
  };
}

function getGroupPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'group',
      appDefinitionId: GROUPS_APP_DEFINITION_ID,
    },
    '/group',
  );
}

function getGroupListPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'groups',
      appDefinitionId: GROUPS_APP_DEFINITION_ID,
    },
    '/groups',
  );
}

async function getMembersPageUrl(site: ISiteApis) {
  try {
    const membersApi = await site.getPublicAPI(MEMBERS_APP_DEFINITION_ID);
    return membersApi
      .getMemberPagePrefix({ type: 'public' })
      .then((data: { prefix: string }) => `/${data.prefix}`)
      .catch(() => '/profile');
  } catch {
    return '/profile';
  }
}

/**
 * v1 - /account/my-groups
 *      !! account is location.prefix, not path !!
 *      ??? do we need to update tpa-router ???
 * v2 - /members-area/:memberSlug/my-groups
 * v3 - /my-groups
 */
async function getMyGroupsPageUrl(api: IWixAPI) {
  try {
    const membersApi = await api.site.getPublicAPI(MEMBERS_APP_DEFINITION_ID);
    const { prefix } = await membersApi.getMemberPagePrefix({
      type: 'private',
    });
    const v1 = await isMembersAreaV1(api);

    return membersApi
      .getSectionUrl({
        widgetId: 'e018cc55-7b1c-4500-a2e5-969f22c8a33a',
        sectionId: 'My Groups',
        appDefinitionId: GROUPS_APP_DEFINITION_ID,
        memberSlug: ':memberSlug',
        memberId: api.user.currentUser.id,
      })
      .then((url: string) => {
        return url
          .replace(
            v1 ? `${api.location.baseUrl}/${prefix}` : api.location.baseUrl,
            '',
          )
          .split('?')[0];
      })
      .catch(() => '/my-groups');
  } catch (e) {
    return '/my-groups';
  }
}

async function isMembersAreaV1(api: IWixAPI) {
  const membersApi = await api.site.getPublicAPI(MEMBERS_APP_DEFINITION_ID);

  try {
    // getRoutes is only supported v2 or v3
    await membersApi.getRoutes();
    return false;
  } catch (e) {
    return true;
  }
}

async function getMembersProfilePageUrl(site: ISiteApis) {
  try {
    const membersApi = await site.getPublicAPI(MEMBERS_APP_DEFINITION_ID);
    const routes: MembersAreaRoute[] = await membersApi.getRoutes();

    const profile = routes.find(
      (route) => route.widgetId === MA_PROFILE_WIDGET_ID,
    );

    return profile ? `/${profile.path}` : '/profile';
  } catch {
    return '/profile';
  }
}

async function getComponentUrl(
  site: ISiteApis,
  sectionIdentifier: {
    appDefinitionId: string;
    sectionId: 'groups' | 'group' | 'membership_plan_picker_tpa' | 'about';
  },
  fallback: string,
) {
  try {
    const { pages } = await site.getSiteStructure({
      includePageId: true,
    } as any);

    const [page] = pages.filter(
      (page: any) =>
        page.tpaPageId === sectionIdentifier.sectionId &&
        page.applicationId === sectionIdentifier.appDefinitionId,
    );

    if (!page) {
      return fallback;
    }

    return page.isHomePage ? '/' : page.url || fallback;
  } catch {
    return fallback;
  }
}

function removeTrailingSlash(url: string) {
  return url.replace(/(\/)\/+/g, '/');
}
